import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Form.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Stepper, Step, StepLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from "axios";
import { API_URL } from "../../redux/actions/api";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
const steps = ['1', '2', '3', '4'];

function Form() {

  const [displayError, setDisplayError] = useState(false)
  const timeoutRef = useRef(null);


  const [required, setRequired] = useState([
    { id: 'math', name: 'מתמטיקה', units: null, grade: null, total: null, width_bonus: null, unit_error: false, can_exclude: false, excluded: false },
    { id: 'english', name: 'אנגלית', units: null, grade: null, total: null, width_bonus: null, unit_error: false, can_exclude: false, excluded: false },
    { id: 'civics', name: 'אזרחות', units: null, grade: null, total: null, width_bonus: null, unit_error: false, can_exclude: false, excluded: false },
    { id: 'hebrew', name: 'הבעה עברית / ערבית', units: null, grade: null, total: null, width_bonus: null, unit_error: false, can_exclude: false, excluded: false },
    { id: 'history', name: 'היסטוריה / תע"י', units: null, grade: null, total: null, width_bonus: null, unit_error: false, can_exclude: false, excluded: false },
    { id: 'digits', name: 'ספרות', units: null, grade: null, total: null, width_bonus: null, unit_error: false, can_exclude: true, excluded: false },
    { id: 'torah', name: 'תנ"ך', units: null, grade: null, total: null, width_bonus: null, unit_error: false, can_exclude: true, excluded: false },
  ]);

  const [optionals, setOptionals] = useState([]);

  const [questions, setQuestions] = useState([
    { id: 'age', label: 'האם אתה מעל גיל 30 ?', value: false, type: 'toggle' },
    { id: 'certificat', label: 'האם יש לך תעודת הנדסאי ?', value: false, type: 'toggle' },
    { id: 'certificat_results', label: 'מה הציון הממוצע של תעודת הנדסאי ?', value: null, type: 'number' },
    { id: 'psyco', label: "האם עשית פסיכומטרי ?", value: false, type: 'toggle' },
    { id: 'psyco_results', label: 'מה הציון בפסיכומטרי ?', value: null, type: 'number' },
    { id: 'previousStudies', label: "האם יש לך תואר אקדמי ?", value: false, type: 'toggle' },
    { id: 'previousStudies_results', label: 'מה הציון הממוצע של התואר אקדמי ?', value: null, type: 'number' },
  ]);


  const [activeStep, setActiveStep] = useState(0);

  const dispatch = useDispatch();

  const handleNext = () => {
    if (activeStep == 1) {
      var invalidForm = required.find(el => (!el.grade || !el.units));
      if (invalidForm !== undefined) {
        setDisplayError(true);
        return;
      };
    }
    setDisplayError(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const sharedValues = {
    5: 25,
    4: 12.5
  };

  const bonus = {
    math: {
      5: 35,
      4: 15
    },
    english: sharedValues,
    chemistry: sharedValues,
    bio: sharedValues,
    computer: sharedValues,
    physics: sharedValues,
    //civics: sharedValues,
    //torah: sharedValues,
    //history: sharedValues,
    //digits: sharedValues,
    //medicine: sharedValues, => 10/20
    //biotech: sharedValues,
    info: sharedValues,
    other: {
      5: 20,
      4: 10
    }
  };

  const inputChange = (index, id, field, value, isOption) => {
    console.log('inputChange')
    var elements = isOption ? [...optionals] : [...required];
    const updatedElements = elements.map((element, i) => {
      if (i == index) {
        console.log(index)
        const updatedElement = {
          ...element,
          [field]: parseFloat(value),
          excluded: false
        };
        var newGrade = '--';
        var total = null;
        console.log(element)
        console.log(updatedElement)
        if (updatedElement.units <= 5 && updatedElement.grade) {
          const bonusKey = bonus.hasOwnProperty(id) ? bonus[id] : bonus['other'];
          newGrade = (updatedElement.units > 3 && updatedElement.grade >= 60) ? updatedElement.grade + bonusKey[updatedElement.units] : updatedElement.grade;
          total = newGrade * updatedElement.units
        }
        const renderElement = {
          ...updatedElement,
          unit_error: updatedElement.units > 5,
          width_bonus: newGrade,
          total: total
        }
        return renderElement;
      }
      return element;
    });
    console.log(updatedElements);
    isOption ? setOptionals(updatedElements) : setRequired(updatedElements);


    /* console.log(required)
    console.log(optionals) */

    /* setTimeout(() => {
      check_optimal();
    }, 1000); */

    check_optimal(isOption ? required : updatedElements, isOption ? updatedElements : optionals);
  };


  const debounce = (func, delay) => {
    console.log('debounce');
    return (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedHandleInputChange = debounce(inputChange, 500);


  const handleInputChange = (i, id, field, value, isOption) => {
    console.log('handleInputChange');

    debouncedHandleInputChange(i, id, field, value, isOption);
    /*  var elements = isOption ? [...optionals] : [...required];
     const updatedElements = elements.map(element => {
       if (element.id == id) {
         console.log(id)
         const updatedElement = {
           ...element,
           [field]: parseFloat(value),
           excluded: false
         };
         var newGrade = '--';
         var total = null;
         console.log(updatedElement.units)
         if (updatedElement.units <= 5 && updatedElement.grade) {
           const bonusKey = bonus.hasOwnProperty(id) ? bonus[id] : bonus['other'];
           newGrade = (updatedElement.units > 3 && updatedElement.grade >= 60) ? updatedElement.grade + bonusKey[updatedElement.units] : updatedElement.grade;
           total = newGrade * updatedElement.units
         }
         console.log(updatedElement.units > 5);
         const renderElement = {
           ...updatedElement,
           unit_error: updatedElement.units > 5,
           width_bonus: newGrade,
           total: total
         }
         return renderElement;
       }
       return element;
     });
     console.log(updatedElements);
     isOption ? setOptionals(updatedElements) : setRequired(updatedElements); */
    /* 
        setTimeout(() => {
          check_optimal();
        }, 1000);
       */
  };



  const excludeSubjects = (subjects) => {

    var combinedSubjects = [...subjects];
    var totalAverage = calculateAverage(combinedSubjects)['average'];
    console.log('totalAverage: ' + totalAverage);

    let improvements = true;

    while (improvements) {
      var maxImprovement = 0;
      var subjectToExclude = null;

      combinedSubjects.forEach((subject) => {
        if (subject.can_exclude && !subject.excluded) {
          console.log(subject.id);
          var tempSubjects = combinedSubjects.map((s) => (s.id === subject.id ? { ...s, excluded: true } : s));
          var newCalc = calculateAverage(tempSubjects);
          var newAverage = newCalc['average'];
          console.log(newAverage);
          var improvement = newAverage - totalAverage;
          console.log(improvement);
          var newTotalUnits = newCalc['units'];
          console.log(newTotalUnits);

          if (improvement > maxImprovement && newTotalUnits >= 20) {
            maxImprovement = improvement;
            subjectToExclude = subject.id;
          }
        }
      });

      if (subjectToExclude) {
        combinedSubjects = combinedSubjects.map((subject) =>
          subject.id === subjectToExclude ? { ...subject, excluded: true } : subject
        );
        totalAverage = calculateAverage(combinedSubjects)['average'];
        console.log(totalAverage);
        console.log(improvements)
      } else {
        console.log(subjectToExclude);
        improvements = false;
      }


    }

    return combinedSubjects;
  };

  const check_optimal = (required, optionals) => {

    var updatedRequired;
    var updatedOptionals;

    console.log(required)
    console.log(optionals)

    var updatedRequired = required.map((subject) => ({ ...subject, excluded: false }));
    var updatedOptionals = optionals.map((subject) => ({ ...subject, excluded: false }));

    console.log(updatedRequired)
    console.log(updatedOptionals)

    /* setRequired(updatedRequired);
    setOptionals(updatedOptionals); */

    var updatedSub = excludeSubjects([...updatedRequired, ...updatedOptionals]);
    console.log(updatedSub);

    updatedRequired = updatedSub.filter((subject) => !optionals.some(opt => opt.id === subject.id));
    updatedOptionals = updatedSub.filter((subject) => optionals.some(opt => opt.id === subject.id));

    console.log(updatedRequired)
    console.log(updatedOptionals)


    setRequired(updatedRequired);
    setOptionals(updatedOptionals);

    console.log(getTotalSum());
  }




  const getTotalSum = () => {
    console.log('get total sum');
    const nonNullRequired = required.filter(element => ((element.total !== null) && (!element.excluded)));
    console.log(nonNullRequired);
    const sumRequired = nonNullRequired.reduce((acc, element) => acc + element.total, 0);
    const sumUnitsReq = nonNullRequired.reduce((acc, element) => acc + element.units, 0);

    const nonNullOpt = optionals.filter(element => ((element.total !== null) && (!element.excluded)));
    const sumOpt = nonNullOpt.reduce((acc, element) => acc + element.total, 0);
    const sumUnitsOpt = nonNullOpt.reduce((acc, element) => acc + element.units, 0);
    return (nonNullRequired.length + nonNullOpt.length) > 0 ? ((sumRequired + sumOpt) / (sumUnitsReq + sumUnitsOpt)).toFixed(2) : '---';

  };

  const calculateAverage = (subjects) => {

    const nonNull = subjects.filter(element => ((element.total !== null) && (!element.excluded)));

    const totalUnits = nonNull.reduce((acc, subject) => acc + subject.units, 0);
    const totalScore = nonNull.reduce((acc, subject) => acc + subject.total, 0);
    return {
      average: totalUnits ? totalScore / totalUnits : 0,
      units: totalUnits
    };
  };





  return (
    <div className="form_container">
      <h1>מחשבון בגרות</h1>

      <Stepper activeStep={activeStep} nonLinear>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} color="inherit">{/* {label} */}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          {activeStep == 0 && <Step1 next={handleNext} />}
          {activeStep == 1 && <Step2 key="step2" displayError={displayError} handleInputChange={handleInputChange} required={required} optionals={optionals} setOptionals={setOptionals} getTotalSum={getTotalSum} />}
          {activeStep == 2 && <Step3 questions={questions} setQuestions={setQuestions} />}
          {activeStep == 3 && <Step4 questions={questions} required={required} optionals={optionals} getTotalSum={getTotalSum} />}
          {activeStep != 0 && <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} className={'toStep buttons_step_' + (activeStep + 1)} >

            {/* <Button

              onClick={() => check_optimal(required, optionals)}
              sx={{ mr: 1 }}
            >
              OPTIMAL
            </Button> */}


            <Button
              color={activeStep == 3 ? "primary" : "inherit"}
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant={activeStep == 3 ? "outlined" : "text"}
            >
              חזרה
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            {activeStep !== steps.length - 1 && <Button className="nextStep" onClick={handleNext} endIcon={<KeyboardArrowLeftRoundedIcon />}>
              {activeStep === steps.length - 2 ? 'סיום' : 'הבא'}
            </Button>}
          </Box>}
        </>
      )
      }



    </div>
  );

}

export default Form;
